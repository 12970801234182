import React, { useContext } from 'react';

import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import GithubButtons from '../GithubButtons/GithubButtons';
import PortfolioContext from '../../context/context';
import { githubButtons } from '../../mock/data';

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { isEnabled } = githubButtons;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()} - Images by{' '}
          <a
            href="https://www.instagram.com/fauxpas.emotions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Patricia Vogel
          </a>{' '}
          and{' '}
          <a href="https://unsplash.com/@scamartist" target="_blank" rel="noopener noreferrer">
            Carl Jorgensen
          </a>
          <br />
          Website developed by{' '}
          <a href="https://me.cratory.de" target="_blank" rel="noopener noreferrer">
            Mark Heimer
          </a>
          . <br />
          Template by{' '}
          <a href="https://github.com/cobidev" target="_blank" rel="noopener noreferrer">
            Jacobo Martínez
          </a>
          <br />
          <a
            href="https://molbitzer-turniere.de/impressum"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impressum
          </a>
        </p>

        {isEnabled && <GithubButtons />}
      </Container>
    </footer>
  );
};

export default Footer;
