import React, { Component } from 'react';

import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import MailSuccess from './MailSuccess';
import Title from '../Title/Title';

const INITIAL_STATE = {
  name: '',
  phone: '',
  mail: '',
  text: '',
  formState: { loading: false, submitted: undefined },
};

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  postMail = async () => {
    const { name, phone, mail, text, formState } = this.state;
    this.setState({ formState: { ...formState, loading: true } });

    try {
      await axios.post(
        process.env.NODE_ENV === 'production'
          ? 'https://contact.cratory.de/mail/tournaments'
          : 'http://localhost:3333/mail/tournaments',
        {
          name,
          phone,
          mail,
          text,
        }
      );
      this.setState({
        formState: {
          ...INITIAL_STATE,
          loading: false,
          submitted: { success: true, error: undefined },
        },
      });
    } catch (error) {
      this.setState(() => {
        const initial = INITIAL_STATE;
        return { ...initial, loading: false, submitted: { success: undefined, error } };
      });
    }
  };

  handleNameStateChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handlePhoneStateChange = (event) => {
    this.setState({ phone: event.target.value });
  };

  handleMailStateChange = (event) => {
    this.setState({ mail: event.target.value });
  };

  handleTextStateChange = (event) => {
    this.setState({ text: event.target.value });
  };

  onFormSubmit = async (event) => {
    event.preventDefault();
    await this.postMail();
  };

  render() {
    const { formState, name, mail, phone, text } = this.state;
    return (
      <section id="contact">
        <Container>
          <Title title="Kontakt" />
          {formState.loading ? (
            <Fade bottom>
              <LoadingSpinner />
            </Fade>
          ) : formState.submitted === undefined ? (
            <Fade bottom duration={1000} delay={800} distance="30px">
              <div className="contact-wrapper">
                <p className="contact-wrapper__text">
                  {this.cta ||
                    'Du hast Anregungen, Wünsche oder würdest gern selbst teilnehmen? Dann schreib uns einfach.'}
                </p>

                <div className="place-center-container">
                  <form onSubmit={this.onFormSubmit} className="grid-container">
                    <label htmlFor="name">Name* </label>
                    <input
                      type="text"
                      id="name"
                      required
                      value={name}
                      onChange={this.handleNameStateChange}
                      placeholder="Dein Name ..."
                    />
                    <label htmlFor="email">E-Mail* </label>
                    <input
                      type="email"
                      id="email"
                      required
                      value={mail}
                      onChange={this.handleMailStateChange}
                      placeholder="Deine E-Mail..."
                    />
                    <label htmlFor="phone">Telefonnummer</label>
                    <input
                      type="tel"
                      id="phone"
                      value={phone}
                      onChange={this.handlePhoneStateChange}
                      placeholder="Deine Telefonnummer..."
                    />
                    <label htmlFor="message">Nachricht*</label>
                    <textarea
                      id="message"
                      name="message"
                      required
                      value={text}
                      onChange={this.handleTextStateChange}
                      rows="3"
                      placeholder="Deine Nachricht..."
                    />

                    <button className="cta-btn cta-btn--resume submit-button" type="submit">
                      Abschicken
                    </button>
                  </form>
                </div>
              </div>
            </Fade>
          ) : formState.submitted.success ? (
            <Fade bottom duration={1000} distance="30px">
              <MailSuccess />
            </Fade>
          ) : (
            <Fade bottom duration={1000} distance="30px">
              <p>Beim Übertragen deiner Nachricht ist ein Fehler aufgetreten :(</p>
              <p>Wir kümmern uns so schnell wie möglich darum.</p>
              <p>
                Versuche es einfach später noch einmal oder schreib uns auf{' '}
                <a href="https://www.instagram.com/molbitzer_turniere/">Instagram</a>
              </p>
            </Fade>
          )}
        </Container>
      </section>
    );
  }
}

export default Contact;
